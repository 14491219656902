<template>
  <svg fill="none" height="28" viewBox="0 0 1936 1936" width="28" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#828282" height="765" rx="116.5" stroke="#181818" stroke-width="29" width="765" x="585.5" y="585.5" />
    <g filter="url(#filter0_f_25_15)">
      <rect fill="#3C3C3C" height="583" rx="53" width="602" x="667" y="677" />
    </g>
    <rect fill="#AAAAAA" height="572" rx="47.5" stroke="#AAAAAA" stroke-width="11" width="591" x="672.5" y="682.5" />
    <rect height="404" rx="38" stroke="url(#paint0_linear_25_15)" stroke-width="18" width="418" x="759" y="766" />
    <rect height="290" rx="38" stroke="url(#paint1_linear_25_15)" stroke-width="18" width="300" x="818" y="823" />
    <path
      d="M1462 1011C1501.76 1011 1534 1043.24 1534 1083L1534 1162C1534 1201.76 1501.76 1234 1462 1234L1390 1234L1390 1011L1462 1011Z"
      fill="#0F0F0F"
    />
    <path
      d="M1462 702C1501.76 702 1534 734.236 1534 774L1534 853C1534 892.764 1501.76 925 1462 925L1390 925L1390 702L1462 702Z"
      fill="#0F0F0F"
    />
    <path
      d="M474 1011C434.236 1011 402 1043.24 402 1083L402 1162C402 1201.76 434.235 1234 474 1234L546 1234L546 1011L474 1011Z"
      fill="#0F0F0F"
    />
    <path
      d="M474 702C434.236 702 402 734.236 402 774L402 853C402 892.764 434.235 925 474 925L546 925L546 702L474 702Z"
      fill="#0F0F0F"
    />
    <path
      d="M1011 1462C1011 1501.76 1043.24 1534 1083 1534L1162 1534C1201.76 1534 1234 1501.76 1234 1462L1234 1390L1011 1390L1011 1462Z"
      fill="#0F0F0F"
    />
    <path
      d="M702 1462C702 1501.76 734.236 1534 774 1534L853 1534C892.764 1534 925 1501.76 925 1462L925 1390L702 1390L702 1462Z"
      fill="#0F0F0F"
    />
    <path
      d="M925 474C925 434.236 892.764 402 853 402L774 402C734.236 402 702 434.236 702 474L702 546L925 546L925 474Z"
      fill="#0F0F0F"
    />
    <path
      d="M1234 474C1234 434.236 1201.76 402 1162 402L1083 402C1043.24 402 1011 434.236 1011 474L1011 546L1234 546L1234 474Z"
      fill="#0F0F0F"
    />
    <path
      d="M868 677H1216C1245.27 677 1269 700.729 1269 730V774C1269 803.271 1245.27 827 1216 827H1018C935.157 827 868 759.843 868 677V677Z"
      fill="#EEEEEE"
    />
    <path
      d="M1188.15 971C1150.05 971 1119.15 940.108 1119.15 902L1119.15 756C1119.15 733.356 1137.51 715 1160.15 715L1228 715C1250.64 715 1269 733.356 1269 756L1269 930C1269 952.644 1250.64 971 1228 971L1188.15 971Z"
      fill="url(#paint2_linear_25_15)"
    />
    <path
      d="M757 428C757 413.641 768.641 402 783 402H845C859.359 402 871 413.641 871 428V546H757V428Z"
      fill="url(#paint3_linear_25_15)"
    />
    <path
      d="M1066 428C1066 413.641 1077.64 402 1092 402H1154C1168.36 402 1180 413.641 1180 428V546H1066V428Z"
      fill="url(#paint4_linear_25_15)"
    />
    <path
      d="M1508 757C1522.36 757 1534 768.641 1534 783L1534 845C1534 859.359 1522.36 871 1508 871L1390 871L1390 757L1508 757Z"
      fill="url(#paint5_linear_25_15)"
    />
    <path
      d="M1508 1066C1522.36 1066 1534 1077.64 1534 1092L1534 1154C1534 1168.36 1522.36 1180 1508 1180L1390 1180L1390 1066L1508 1066Z"
      fill="url(#paint6_linear_25_15)"
    />
    <defs>
      <filter
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
        height="682.2"
        id="filter0_f_25_15"
        width="701.2"
        x="617.4"
        y="627.4"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_25_15" stdDeviation="24.8" />
      </filter>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_25_15" x1="750" x2="968" y1="1177.67" y2="941">
        <stop stop-color="#EEEEEE" />
        <stop offset="1" stop-color="#AAAAAA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_25_15"
        x1="809"
        x2="968.118"
        y1="1121.03"
        y2="948.403"
      >
        <stop stop-color="#EEEEEE" />
        <stop offset="1" stop-color="#AAAAAA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_25_15"
        x1="1194.08"
        x2="1194.08"
        y1="715"
        y2="971"
      >
        <stop offset="0.585938" stop-color="#EEEEEE" />
        <stop offset="1" stop-color="#AAAAAA" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint3_linear_25_15" x1="814" x2="814" y1="402" y2="546">
        <stop stop-color="#3D3D3D" />
        <stop offset="0.652778" stop-color="#0F0F0F" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint4_linear_25_15" x1="1123" x2="1123" y1="402" y2="546">
        <stop stop-color="#BBBBBB" />
        <stop offset="0.652778" stop-color="#0F0F0F" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_25_15" x1="1534" x2="1390" y1="814" y2="814">
        <stop stop-color="#C6C6C6" />
        <stop offset="0.652778" stop-color="#0F0F0F" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint6_linear_25_15" x1="1534" x2="1390" y1="1123" y2="1123">
        <stop stop-color="#969696" />
        <stop offset="0.652778" stop-color="#0F0F0F" />
      </linearGradient>
    </defs>
  </svg>
</template>
