import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Inloggen",
  signUp: "Aanmelden",
  getStarted: "Aan de slag",
  started: "Start",
  howToUse: "Over",
  generate: "Genereren",
  reset: "Reset",
  download: "Downloaden",
  select: "Selecteer",
  yes: "Ja",
  no: "Geen",
  confirm: "Bevestig",
  generateImage: "Afbeelding genereren",
};
