import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Login",
  signUp: "Iscriviti",
  getStarted: "Per iniziare",
  started: "Inizio",
  howToUse: "Circa",
  generate: "Generare",
  reset: "Reset",
  download: "Scaricare",
  select: "Selezionare",
  yes: "Sì",
  no: "No",
  confirm: "Confermare",
  generateImage: "Genera immagine",
};
