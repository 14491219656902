import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Anmeldung",
  signUp: "Anmeldung",
  getStarted: "Anfangen",
  started: "Start",
  howToUse: "Über",
  generate: "Erzeugen Sie",
  reset: "Zurücksetzen",
  download: "Herunterladen",
  select: "Wählen Sie",
  yes: "Ja",
  no: "Nein",
  confirm: "Bestätigen Sie",
  generateImage: "Bild generieren",
};
