import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Amount",
  description: "Description",
  neurons: "Quanties",
  date: "Date",
  // howToUse: "How to use:",
  howToUse: "About:",
  profile: "Profile",
  password: "Password",
  // generatingPoems: "Generating Poems",
  generatingPoems: "Poem Generator",
  stanzaCount: "Stanza Count",
  stanzaSize: "Stanza Size",
  paraphrasing: "Paraphrasing",
  style: "Style",
  // generatorImages: "Generator Images",
  generatorImages: "Image",
  imageService: "Neuro-Vision",
  // generatingImages: "Generating Images",
  generatingImages: "Image Generator",
  creative: "Creative",
  phone: "Phone",
  registrationNumber: "Registration Number",
  whereWeAreLocated: "Where we are located",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  audioService: "VoxAI",
  // generatingAudio: "Generating text in audio",
  generatingAudio: "Text-to-Audio",
  // generatorAudio: "Generator Audio",
  generatorAudio: "Audio",
  buyNeurons: "Buy Quanties",
  swap: "SWAP",
  resetPassword: "Reset Password",
  enterSubject: "Enter Subject",
  selectStyle: "Select style",
  pleaseLogin: "Please login to proceed",
  noNeurons: "Your balance is low. Please, purchase AIera to proceed.",
  instructions: "Instructions",
  selectTheme: "Select Theme",
  voiceSelection: "Voice Selection",
  enterText: "Enter text",
  generation: "Generation",
  privacyPolicy: "Privacy Policy",
  termsAndConditions: "Terms and Conditions",
  contactUs: "Contact Us",
  faq: "FAQ",
  selectVoice: "Select Voice",
  textTitle: "Text",
  imageTitle: "Image",
  audioTitle: "Audio",
};
