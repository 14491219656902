type Route = {
  PATH: string;
  NAME: string;
};

export interface RoutesType {
  [key: string]: Route;
}

export const ROUTES: RoutesType = {
  LOGIN: {
    PATH: "/login",
    NAME: "login",
  },

  ROOT: {
    PATH: "/",
    NAME: "home",
  },

  NOT_FOUND: {
    PATH: "/not-found",
    NAME: "notFound",
  },

  PRIVACY_POLICY: {
    PATH: "/privacy-policy",
    NAME: "privacyPolicy",
  },

  TERMS_OF_USE: {
    PATH: "/terms-and-conditions",
    NAME: "termsOfUse",
  },

  CONTACT_US: {
    PATH: "/contact-us",
    NAME: "contactUs",
  },

  TEXT_SERVICE: {
    PATH: "/text",
    NAME: "text",
  },

  IMAGE_SERVICE: {
    PATH: "/neuro-vision",
    NAME: "image",
  },

  AUDIO_SERVICE: {
    PATH: "/audio",
    NAME: "audio",
  },

  POEMS_SERVICE: {
    PATH: "/generate-poems",
    NAME: "textPoems",
  },

  PARAPHRASING_SERVICE: {
    PATH: "/paraphrase-text",
    NAME: "textParaphrasing",
  },

  CREATIVE_SERVICE: {
    PATH: "/creative-mode",
    NAME: "textCreative",
  },

  IMAGE_GENERATOR: {
    PATH: "/image-generator",
    NAME: "imageGenerator",
  },

  AUDIO_GENERATOR: {
    PATH: "/audio",
    NAME: "audioGenerator",
  },

  AUDIO_GENERATOR_NEW: {
    PATH: "/text-to-audio",
    NAME: "audioGenerator",
  },

  PROFILE: {
    PATH: "/profile",
    NAME: "profile",
  },

  USER_TRANSACTIONS: {
    PATH: "/user-transactions",
    NAME: "user-transactions",
  },

  BUY_NEURONS: {
    PATH: "/buy-quanties",
    NAME: "buy-neurons",
  },

  LOGOUT: {
    PATH: "/logout",
    NAME: "logout",
  },

  ADMIN_LOGIN: {
    PATH: "/admin-login",
    NAME: "admin-login",
  },

  ADMIN_PANEL: {
    PATH: "/admin-panel",
    NAME: "admin-panel",
  },

  REGISTRATION: {
    PATH: "/registration",
    NAME: "registration",
  },

  ARTICLES: {
    PATH: "/articles",
    NAME: "articles",
  },

  ARTICLES_DETAILS: {
    PATH: "/articles-details",
    NAME: "articles-details",
  },
};

export const ROUTE_MAPPING: { [key: string]: string } = {
  home: "home",
  text: "text",
  textPoems: "text",
  textParaphrasing: "text",
  textCreative: "text",
  image: "image",
  imageGenerator: "image",
  audio: "audio",
  audioGenerator: "audio",
  articles: "articles",
};
