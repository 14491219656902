import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Quantità",
  description: "Descrizione",
  neurons: "AIera",
  date: "Data",
  howToUse: "Come usare:",
  profile: "Profilo",
  password: "Password",
  generatingPoems: "Generazione di poesie",
  stanzaCount: "Numero di stanze",
  stanzaSize: "Dimensione della stanza",
  paraphrasing: "Parafrasando",
  style: "Stile",
  generatorImages: "Generatore di immagini",
  imageService: "Servizi di immagini",
  generatingImages: "Generazione di immagini",
  creative: "Creativo",
  contactUs: "Contattaci",
  phone: "Telefono",
  registrationNumber: "Numero di registrazione",
  whereWeAreLocated: "La nostra posizione",
  newPassword: "Nuova password",
  confirmPassword: "Conferma password",
  audioService: "Servizi audio",
  generatingAudio: "Generazione di testo in audio",
  generatorAudio: "Generazione di audio",
  buyNeurons: "Acquista Quanties",
  swap: "SCAMBIO",
  enterSubject: "Inserisci Oggetto",
  selectStyle: "Seleziona lo stile",
  pleaseLogin: "Effettuare il login per procedere",
  instructions: "Istruzioni",
  selectTheme: "Seleziona il tema",
  resetPassword: "Resetta la password",
  noNeurons: "Il tuo saldo è basso. Per favore, acquista i AIera per procedere.",
  voiceSelection: "Selezione voce",
  generation: "Generazione",
  enterText: "Inserire il testo",
  privacyPolicy: "Politica sulla privacy",
  termsAndConditions: "Termini e condizioni",
  textTitle: "Testo",
  imageTitle: "Image",
  audioTitle: "Audio",
};
