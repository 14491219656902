import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Montant",
  description: "Description",
  neurons: "AIera",
  date: "Date",
  howToUse: "Comment l'utiliser:",
  profile: "Profil",
  password: "Mot de passe",
  generatingPoems: "Générer des poèmes",
  stanzaCount: "Nombre de strophes",
  stanzaSize: "Taille Stanza",
  paraphrasing: "Paraphrase",
  style: "Style",
  generatorImages: "Générateur d'images",
  imageService: "Services de l'image",
  generatingImages: "Générer des images",
  creative: "Créatif",
  contactUs: "Nous contacter",
  phone: "Téléphone",
  registrationNumber: "Numéro d'enregistrement",
  whereWeAreLocated: "Où nous sommes situés",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  audioService: "Services audio",
  generatingAudio: "Générer du texte dans l'audio",
  generatorAudio: "Génération audio",
  buyNeurons: "Acheter des Quanties",
  swap: "ÉCHANGE",
  enterSubject: "Entrer le Sujet",
  selectStyle: "Sélectionnez le style",
  pleaseLogin: "Veuillez vous connecter pour continuer",
  instructions: "Instructions",
  selectTheme: "Sélectionne un thème",
  resetPassword: "Réinitialiser le mot de passe",
  noNeurons: "Votre solde est faible. S'il vous plaît, achetez des AIera pour continuer.",
  voiceSelection: "Sélection vocale",
  generation: "Génération",
  enterText: "Saisir le texte",
  privacyPolicy: "Politique de confidentialité",
  termsAndConditions: "Conditions d'utilisation",
  textTitle: "Texte",
  imageTitle: "Image",
  audioTitle: "Audio",
};
