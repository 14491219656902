import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Bienvenue à l'AIEra !",
  homeSubtitle:
    "Découvrez la puissance de l’intelligence artificielle à portée de main. Notre plateforme propose des outils faciles à utiliser et des fonctionnalités avancées pour vous aider à créer, innover et vous développer.\n",
  homeSubtitle2:
    "Que vous soyez développeur, entrepreneur ou passionné curieux, notre plateforme vous permet d'exploiter le potentiel de l'IA et de mener vos projets vers le succès. Rejoignez-nous et changez votre façon de penser la technologie.\n",
  homeHeading2: "Essayez-le maintenant",
  serviceCard1:
    "Audio est un temple de la renommée où votre texte prend vie à votre commande. Je peux transformer votre texte en fichiers audio clairs et sincères, donnant à vos mots un son réel et unique. \n" +
    "            Mes capacités vocales peuvent être utilisées pour un large éventail de tâches, de la synthèse vocale à la création de documents pratiques et attrayants.",
  serviceCard2:
    "La Generate Images est un trésor de créativité qui ouvre un monde d'images visuelles incroyables. Je peux créer des images graphiques qui incarnent des descriptions et des idées abstraites.\n" +
    "            Le résultat est une harmonie de formes et de couleurs qui vous permet de voir votre monde sous un angle nouveau et inspirant.",
  serviceCard3:
    "Text Mode est un outil qui vous offre la possibilité de créer des textes uniques, des poèmes et bien plus encore. Je suis prêt à vous offrir un flux inépuisable de mots qui enrichissent votre imagination et inspirent votre créativité.\n" +
    "            Mes algorithmes de génération de texte sont dotés d'une grande puissance qui vous aidera à créer des œuvres étonnantes et vivantes, \n" +
    "            vous immerger dans vos idées.",
  textServiceHeading: "Services de texte",
  textServiceName: "Générateur de poèmes",
  textServiceHint:
    "Génère des poèmes sur demande tout en préservant le style.\n" +
    "Exemple de demande : « Écrire une nouvelle sur un magicien des temps modernes dans le style d'Edgar Allan Poe ».\n",
  imageServiceHint:
    "Génère des images sur demande dans le style choisi. Exemple de demande : « Robots géants steampunk en chemise hawaïenne ».",
  creativeServiceHint: "Génère 3 idées créatives sur un sujet choisi.",
  paraphraseServiceHint: "Paraphrase le texte saisi dans le style choisi.",
  instruction1: "Saisir le sujet (pas plus de 300 caractères)",
  instruction2: "Sélectionner le style, le nombre et la taille des strophes",
  instruction3: "Cliquez sur générer",
  loginWarning1: "En cliquant sur se connecter ou en poursuivant avec les autres options ci-dessous, vous acceptez ",
  loginWarning2: "Termes et Сonditions",
  loginWarning3: " et j'ai lu le ",
  loginWarning4: "Politique de Confidentialité",
  newUser: "Nouveau sur AI Factory ? S'inscrire",
  forgotPassword: "Vous avez oublié votre mot de passe ?",
  logoutWarning: "Déconnexion de votre compte ?",
  signUpWarning: "Vous avez déjà un compte ? S'identifier",
  audioHint:
    "La synthèse vocale donne vie à votre texte à votre demande. Je peux convertir votre texte en fichiers audio clairs et sincères, en donnant un son unique à vos mots.",
  minBuyWarning: "Le montant minimum de dépôt par transaction est de 10 EUR.",
  maxBuyWarning: "Le montant maximum de dépôt par transaction est de 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "J'ai lu, compris et accepté le",
  buyAgreement2: "et au",
  buyAgreement3: " du site internet",
  buyAgreement4: "Politique de Confidentialité",
  buyAgreement5: "Conditions d'utilisation",
  textServiceTitle1: "Générateur de poèmes",
  textServiceTitle2: "Paraphraser le texte",
  textServiceTitle3: "Mode créatif",
};
