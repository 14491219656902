import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Welkom bij AIera!",
  homeSubtitle:
    "Ervaar de kracht van kunstmatige intelligentie binnen handbereik. Ons platform biedt gebruiksvriendelijke tools en geavanceerde functies waarmee u kunt bouwen, innoveren en groeien.\n",
  homeSubtitle2:
    "Of u nu een ontwikkelaar, ondernemer of nieuwsgierige liefhebber bent, ons platform stelt u in staat het potentieel van AI te benutten en uw projecten tot een succes te maken. Doe met ons mee en verander de manier waarop u over technologie denkt.\n",
  homeHeading2: "Probeer het nu",
  serviceCard1:
    "Audio is een eregalerij waar uw tekst tot leven komt op uw commando. Ik kan uw tekst omzetten in oprechte en duidelijke audiobestanden, waardoor uw woorden een echte en unieke klank krijgen. \n" +
    " Mijn stemmogelijkheden kunnen worden ingezet voor een breed scala aan taken, van tekst-naar-spraak tot het creëren van boeiend, praktisch materiaal.",
  serviceCard2:
    "Generate Images is een schatkamer vol creativiteit die een wereld van ongelooflijke visuele beelden opent. Ik kan grafische afbeeldingen maken die abstracte beschrijvingen en ideeën belichamen.\n" +
    " De resultaten tonen een harmonie van vormen en kleuren, waardoor u uw wereld kunt zien vanuit een nieuwe, inspirerende perspectief.",
  serviceCard3:
    "Text Mode is een tool die u de gelegenheid biedt om unieke teksten, gedichten en meer te creëren. Ik sta klaar om u een onuitputtelijke stroom van woorden te geven die uw verbeelding verrijken en uw creativiteit inspireren.\n" +
    " Mijn tekstgeneratie-algoritmen zijn gevuld met kracht die u zal helpen geweldige en levendige werken te creëren, \n" +
    " waardoor u ondergedompeld wordt in uw ideeën.",
  textServiceHeading: "Text Services",
  // textServiceName: "Generating Poems",
  textServiceName: "Gedichtengenerator",
  textServiceHint:
    "Genereert gedichten op verzoek met behoud van stijl.\n" +
    "Voorbeeld verzoek: “Schrijf een kort verhaal over een tovenaar in de moderne tijd in de stijl van Edgar Allan Poe”.\n",
  imageServiceHint:
    "Genereert afbeeldingen op verzoek in de gekozen stijl. Voorbeeld verzoek: “Gigantische steampunk robots in Hawaï shirts”.",
  creativeServiceHint: "Genereert 3 creatieve ideeën over een gekozen onderwerp.",
  paraphraseServiceHint: "Parafraseert de ingevoerde tekst in een gekozen stijl.",
  instruction1: "Voer het onderwerp in (niet meer dan 300 tekens)",
  instruction2: "Selecteer de stijl, het aantal en de grootte van de strofen",
  instruction3: "Klik op genereren",
  loginWarning1: "Door op inloggen te klikken, of door te gaan met de andere opties hieronder, gaat u hiermee akkoord ",
  loginWarning2: "Algemene Мoorwaarden",
  loginWarning3: " en heb de gelezen ",
  loginWarning4: "Privacybeleid",
  newUser: "Nieuw bij AI Factory? Aanmelden",
  forgotPassword: "Wachtwoord vergeten?",
  logoutWarning: "Afmelden bij je account?",
  signUpWarning: "Heb je al een account? Aanmelden",
  audioHint:
    "Text-to-Speech brengt je tekst tot leven op jouw commando. Ik kan je tekst omzetten in heldere en oprechte audiobestanden, waardoor je woorden een uniek geluid krijgen.",
  minBuyWarning: "Het minimale stortingsbedrag per transactie is 10 EUR.",
  maxBuyWarning: "Het maximale stortingsbedrag per transactie is 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "Ik heb het gelezen, begrepen en ga ermee akkoord\n",
  buyAgreement2: "en naar de",
  buyAgreement3: " van de website",
  buyAgreement4: "Privacybeleid",
  buyAgreement5: "Gebruiksvoorwaarden",
  textServiceTitle1: "Gedichtengenerator",
  textServiceTitle2: "Parafraseer tekst",
  textServiceTitle3: "Creatieve modus",
};
