import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Login",
  signUp: "Sign Up",
  getStarted: "Get Started",
  started: "Start",
  howToUse: "About",
  generate: "Generate",
  paraphrase: "Paraphrase",
  reset: "Reset",
  download: "Download",
  select: "Select",
  yes: "Yes",
  no: "No",
  confirm: "Confirm",
  generateImage: "Generate Image",
};
